.button {
  font-family: "Playfair Display", monospace;
  font-weight: 400;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.buttonFitContent {
  width: auto; /* This ensures the button fits its content */
  padding-left: 15px;
  padding-right: 15px;
}

.button:hover {
  background-color: #333;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.button:active {
  transform: translateY(1px);
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
