.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 400px;
  text-align: center;
  font-family: 'Playfair Display', sans-serif;
  margin: 0 10px;
}

.modal h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.primaryButton {
  width: 100%;
  padding: 12px 0;
  margin-top: 20px;
}

.secondaryButton {
  width: 100%;
  padding: 12px 0;
  margin-top: 15px;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
}

.secondaryButton:hover {
  background-color: #f0f0f0;
}

.textbox {
  margin-bottom: 16px; /* Already in APTextbox's styles */
}
