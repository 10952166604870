.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 400px;
  text-align: center;
  font-family: 'Playfair Display', sans-serif; /* Set the modal to use Playfair Display */
  margin: 0 10px;
}

.title {
  font-size: 28px; /* Ensure font-size is 28px */
  font-weight: 700; /* Bold font */
  margin-bottom: 20px;
  font-family: 'Playfair Display', sans-serif; /* Ensure the title uses Playfair Display */
}

.image {
  width: 100%;
  height: auto;
  max-height: 200px;
  border-radius: 10px;
  margin-bottom: 20px;
  object-fit: contain;
}

.description {
  color: #808080;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Epilogue', sans-serif; /* Use Epilogue for the description */
}

.selectWrapper {
  margin-bottom: 20px;
  border: none;
}

.label {
  display: block;
  font-size: 14px;
  color: #808080;
  margin-bottom: 5px;
  font-family: 'Epilogue', sans-serif; /* Ensure the label uses Epilogue */
}

.unitsWrapper {
  margin-bottom: 20px;
}

.unitControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unitButton {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  font-family: 'Epilogue', sans-serif;
}

.unitDisplay {
  font-size: 16px;
  margin: 0 15px;
}
