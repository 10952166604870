.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    width: 400px;
    text-align: center;
    position: relative;
    font-family: 'Playfair Display', sans-serif; /* Applying Playfair Display font */
    margin: 0 10px;
  }

  .modal h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .modal p {
    color: #808080; /* Setting description text color */
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .closeButton {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Playfair Display', sans-serif; /* Ensuring button text uses Playfair Display font */
    font-size: 16px;
    margin-top: 20px;
  }
  
  .closeButton:hover {
    background-color: #444;
  }
