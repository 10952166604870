.textbox {
    width: calc(100% - 26px); /* Adjust the width to account for padding inside the modal */
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    color: #333;
    font-family: 'Playfair Display', sans-serif; /* Consistent font family */
    margin-bottom: 16px; /* Add margin to separate the textboxes */
  }
  
  .textbox::placeholder {
    color: #808080; /* Placeholder text color */
    font-family: 'Playfair Display', sans-serif; /* Ensuring placeholder uses the same font */
  }
  
  .textbox:focus {
    outline: none;
    border-color: #000; /* Change border color on focus */
  }
  