.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 4px;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #000;
}
