.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 400px;
  text-align: center;
  font-family: 'Playfair Display', sans-serif;
  margin: 0 10px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Playfair Display', sans-serif;
}

.price {
  font-size: 24px;
  font-weight: 700;
  color: #808080;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.billingCycle {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.cycleOption {
  cursor: pointer;
  font-family: 'Playfair Display', sans-serif;
  color: #808080;
}

.activeCycle {
  color: #000;
}

.description {
  color: #808080;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Epilogue', sans-serif;
}

.primaryButton {
  width: 100%;
  padding: 12px 0;
  margin-top: 20px;
}

.secondaryButton {
  width: 100%;
  padding: 12px 0;
  margin-top: 10px; 
}
