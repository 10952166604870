.pageWrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Epilogue', sans-serif;
}

.pageHeader {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Playfair Display', serif;
}

.totalOrders {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.boardWrapper {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.footerMessage {
  margin-top: 40px;
  font-size: 14px;
  color: #333;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

.back {
  display: inline-block;
  padding: 10px 0;
  color: #000;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.back:hover {
  text-decoration: underline;
}