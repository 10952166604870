.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 400px;
  text-align: center;
  position: relative;
  font-family: 'Playfair Display', sans-serif; /* Applying Playfair Display font */
  margin: 0 10px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: 'Playfair Display', sans-serif; /* Ensuring the title uses Playfair Display */
}

.referralCodeContainer {
  position: relative;
  margin-bottom: 20px;
}

.label {
  display: block;
  font-size: 14px;
  color: #808080;
  margin-bottom: 10px;
  font-family: 'Epilogue', sans-serif;
}

.textbox {
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  font-family: 'Epilogue', sans-serif;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Ensures spacing between buttons */
}

.primaryButton {
  width: 100%;
}

.secondaryButton {
  width: 100%;
}
