.codeBoxContainer {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 12px; /* Adjust padding to maintain height consistency */
  height: 44px; /* Set the height to match the textbox height */
  background-color: #f9f9f9;
  font-family: 'Epilogue', sans-serif;
}

.codeBox {
  width: 100%;
  border: none;
  font-size: 16px;
  color: #808080;
  background-color: transparent;
  outline: none;
  cursor: default;
  font-family: 'Epilogue', sans-serif;
  padding-left: 10px;
  padding-top: 5px; /* Slightly adjust padding to lower the text */
}

.codeBox:focus {
  outline: none;
}

.icon {
  margin-right: 8px;
  color: #808080;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
