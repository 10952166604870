.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 400px;
  text-align: center;
  font-family: 'Playfair Display', sans-serif;
  max-height: 80%;
  overflow-y: auto;
  margin: 0 10px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.cartItemsContainer {
  border-top: 1px solid #ccc;
  margin-bottom: 10px;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.productInfo {
  flex-grow: 1;
  text-align: left;
}

.productName {
  color: #808080;
  font-family: 'Epilogue', sans-serif;
  margin-bottom: 5px;
}

.productPrice {
  display: flex;
  align-items: center;
}

.currentPrice {
  font-size: 16px;
  margin-right: 10px;
  font-weight: 700;
  color: #000;
}

.originalPrice {
  text-decoration: line-through;
  color: #ccc;
  font-size: 14px;
}

.quantity {
  font-family: 'Epilogue', sans-serif;
  margin-right: 10px;
}

.trashIcon {
  cursor: pointer;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 15px 0;
}

.cartSummary {
  text-align: left;
  margin-bottom: 20px;
}

.totalRow {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.strikethrough {
  text-decoration: line-through;
  color: #ccc;
}

.saved {
  color: red;
}

.checkoutButton {
  width: 100%;
  padding: 12px 0;
  font-family: 'Playfair Display', sans-serif;
}
