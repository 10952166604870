.sliderWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 180px;
  padding: 6px;
  border: 1px solid #808080;
  border-radius: 8px;
  border-color: #000;
  background-color: white;
  height: 23px; /* Default height for mobile */
}


@media (min-width: 768px) {
    .sliderWrapper {
        height: 26px; 
    }
}


.sliderPrice {
  width: 60px; /* Fixed width to keep the size consistent */
  text-align: center; /* Centers the price text */
  padding: 5px;
  background-color: white;
  border: 1px solid #808080;
  border-radius: 5px;
  font-size: 12px;
  color: black;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px; /* Makes the slider a bit taller */
  background: white;
  border: 1px solid #808080;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: transparent;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: black;
  border: 2px solid black;
  margin-top: -5px; /* Aligns the thumb with the track */
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: black;
  border: 2px solid black;
  cursor: pointer;
}
