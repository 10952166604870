.card {
    width: 100%;
    max-width: 300px;
    height: 400px;
    /* Set fixed height */
    display: flex;
    flex-direction: column;
    font-family: "Epilogue", monospace;
    background-color: #fff;
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 60%;
    /* Adjust as needed */
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.exclusive {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: beige;
    color: #000;
    border-radius: 2px;
    opacity: 0.8;
    padding: 2px 8px;
    font-size: 12px;
    text-transform: lowercase;
}

.detailsWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow-y: auto;
}

.details {
    padding: 10px 0;
}

.brand {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 5px 0;
    text-transform: uppercase;
}

.name {
    font-size: 14px;
    margin: 0 0 5px 0;
}

.description {
    font-size: 12px;
    color: #666;
    margin: 0 0 10px 0;
}

.priceContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.originalPrice {
    font-size: 14px;
    text-decoration: line-through;
    color: #888;
}

.salePrice {
    font-size: 14px;
    font-weight: normal;
    color: #e74c3c;
}

.addToCart {
    display: inline-block;
    padding: 10px 0;
    color: #000;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

.addToCart:hover {
    text-decoration: underline;
}

.imageSkeleton {
    height: 60% !important;
}
.brandSkeleton {
    margin-top: 2% !important;
    height: 8% !important;
    width: 60%;
}
.productSkeleton {
    margin-top: 1%;
    height: 5% !important;
    width: 80%;
}
.priceSkeleton {
    margin-top: 1%;
    height: 5% !important;
    width: 20%;
}
.addToCartSkeleton {
    margin-top: 5% !important;
    height: 5%;
    width: 40%;
}