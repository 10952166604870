.header {
    margin-bottom: 20px;
}

.title {
    font-family: 'Playfair Display', sans-serif;
    text-align: center;
    font-size: 36px;
    margin-bottom: 60px;
}

.topBar {
    display: flex;
    justify-content: flex-end; /* Right-aligned on desktop */
    gap: 20px; /* Standard gap for desktop */
    margin-bottom: 60px;
}

.contact,
.login,
.cart,
.account,
.orders,
.referrals,
.logout,
.signup {
    text-decoration: none;
    color: #000;
    font-size: 14px;
    cursor: pointer;
}

.contact:hover,
.login:hover,
.cart:hover,
.account:hover,
.orders:hover,
.referrals:hover,
.logout:hover,
.signup:hover {
    color: grey;
}

.selected {
    color: grey;
}

@media (max-width: 768px) {
    .topBar {
        justify-content: center; /* Centering items on mobile */
        flex-wrap: nowrap; /* Ensure items do not wrap */
        gap: 5px; /* Reduced gap for mobile */
    }
    
    .contact,
    .login,
    .cart,
    .account,
    .orders,
    .referrals,
    .logout,
    .signup {
        font-size: 10px; /* Further reduce font size for mobile */
        padding: 5px 5px; /* Reduce padding to save space */
        flex-grow: 1; /* Allow items to grow to fill the space if necessary */
        text-align: center; /* Ensure labels are centered within each link */
    }
}
