.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  width: 300px;
  text-align: center;
  font-family: 'Playfair Display', sans-serif;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Set height to ensure the spinner stays in the center */
  margin: 0 auto; /* Ensure the loader container itself is centered */
}
