.board {
  width: 100%;
  font-family: 'Epilogue', sans-serif;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto; /* Allow horizontal scrolling */
}

.orderRow {
  display: grid;
  grid-template-columns: auto minmax(150px, 1fr) minmax(120px, 1fr) minmax(80px, 1fr) minmax(100px, 1fr) auto;
  gap: 16px;
  padding: 16px;
  align-items: center;
  white-space: nowrap; /* Prevent text wrapping */
}


.header, .orderRow {
  display: grid;
  grid-template-columns: auto minmax(150px, 1fr) minmax(120px, 1fr) minmax(80px, 1fr) minmax(120px, 1fr) minmax(120px, auto); /* Increased width for last two columns */
  gap: 16px;
  padding: 16px;
  align-items: center;
}


.header {
  border-bottom: 2px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
  font-weight: bold;

}

.columnHeader {
  font-size: 14px;
  color: #333;
  font-family: 'Epilogue', sans-serif;
}

.arrow {
  display: flex;
  align-items: center;
}

.orderId {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.orderDate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateX(-8px);
}

.orderAmount {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateX(-2px);
}

.orderPayment {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translateX(2px);
}

@media (max-width: 768px) {
  .orderPayment {
    transform: translateX(-12px);
  }
}

.trackButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Playfair Display", monospace;
  white-space: nowrap;
  margin-left: 36px;
  transform: translateX(-30px);
}

@media (max-width: 768px) {
  .trackButton {
    transform: translateX(-46px);
  }
}

.trackButton svg {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.productList {
  padding: 0 16px 0 calc(16px + 22px);
  grid-column: 1 / -1;
}

.productRow {
  display: flex;
  justify-content: space-between;
  color: #333;
  padding: 16px 0;
  border-bottom: 1px solid #e0e0e0;
}

.productRow:last-child {
  border-bottom: none;
}

.chevron {
  width: 12px;
  height: 12px;
  transition: transform 0.3s ease;
}

.chevronDown {
  transform: rotate(90deg);
}

.rowSkeleton {
  display: grid;
  grid-template-columns: auto minmax(150px, 1fr) minmax(120px, 1fr) minmax(80px, 1fr) minmax(100px, 1fr) auto;
  gap: 16px;
  border-bottom: 1px solid #ccc;
  padding: 16px;
}

.cellSkeleton {
  height: 20px;
}

.price {
  font-family: 'Epilogue', sans-serif; 
}

