.accountContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Epilogue', sans-serif;
}

.pageHeader {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Playfair Display', serif;
}

.billingCycle {
  font-size: 16px;
  color: #333;
  text-align: left;
  font-weight: normal;
  margin-bottom: 20px;
  font-family: 'Epilogue', sans-serif;
}

.subHeader {
  font-size: 20px;
  color: #333;
  text-align: left;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: 'Epilogue', sans-serif;
}

.boardWrapper {
  margin-bottom: 40px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.buttonWrapper>button {
  width: fit-content;
}

.back {
  display: inline-block;
  padding: 10px 0;
  color: #000;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.back:hover {
  text-decoration: underline;
}

.accountBoard {
  max-height: 500px;
  overflow-y: scroll;
}