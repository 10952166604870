.button {
  font-family: "Playfair Display", monospace;
  font-weight: 400;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid #000000;
}

.buttonFitContent {
  width: auto; /* This ensures the button fits its content */
  padding-left: 15px;
  padding-right: 15px;
}

.button:hover {
  background-color: #F0F0F0;
}

.button:active {
  background-color: #E0E0E0;
  transform: translateY(1px);
}

/* If you want to ensure the text doesn't wrap */
.buttonText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}