.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-family: 'Playfair Display', serif;
  margin: 0 10px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.description {
  color: #808080;
  font-size: 16px;
  margin-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 768px) {
  .buttonsContainer {
    flex-direction: column; 
  }
}

.primaryButton, 
.secondaryButton {
  width: 100%;
  padding: 10px;
}

