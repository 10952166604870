.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: "Epilogue", sans-serif;
}

.filters {
    display: flex;
    justify-content: center; /* Center items on desktop */
    gap: 10px; /* Standard gap on desktop */
    margin-bottom: 20px;
}

.filterButton {
    background-color: #fff;
    font-size: 12px;
    cursor: pointer;
    max-height: 60px;
    width: 140px !important; /* Fixed width on desktop */
}

.filterOption {
    font-size: 12px;
}

.filterDescriptor {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.productGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 10px), 1fr));
    gap: 20px;
}

.noProducts {
    text-align: center;
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .productGrid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    .filterButton {
        width: 140px !important;
    }
}

/* Adjustments specifically for mobile */
@media (max-width: 768px) {
    .filters {
        justify-content: space-between; /* Ensure items are spaced evenly */
        flex-wrap: wrap; /* Allow wrapping to multiple lines */
    }

    .filterButton, .sliderWrapper {
        flex: 0 0 47%; /* Tighter flex basis to fit on small screens */
        min-width: 115px; /* Ensure they don't shrink too much */
        width: auto !important; /* Allow width to auto-adjust */
        font-size: 10px; /* Smaller font size for mobile */
    }

    .filterOption {
        font-size: 10px;
    }

    /* Further reduce gap for very small screens */
    .filters {
        gap: 5px; /* Minimal gap to fit 2x2 grid */
    }
}
