.container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.subheading {
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.instructions {
  font-family: 'Epilogue', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.instructions strong {
  color: #000;
  /* Ensure "How it works" is black */
}

.instructions ol {
  padding-left: 0;
  list-style-type: none;
  /* Remove list indent */
  margin-left: 0;
}

.instructions ol li {
  margin-bottom: 5px;
}

.link {
  color: #000000;
  /* Set link color to black */
  font-size: 16px;
}

.buttonContainer {
  margin-bottom: 30px;
  text-align: left;
  /* Ensure button is on the left side */
}

.primaryButton {
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  width: 30%;
  /* Adjust text size to balance the button */
  padding: 8px 20px;
  /* Adjust padding to fit text better */
  display: inline-block;
}

.board {
  margin-top: 30px;
}

.fullWidthBackground {
  width: 100%;
  background-color: #fff;
  /* Ensure the background color stretches full width */
}

.back {
  display: inline-block;
  padding: 10px 0;
  color: #000;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.back:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .primaryButton {
    width: 100%;
    /* Ensure button takes full width on smaller screens */
  }
}