.selectContainer {
  position: relative;
  width: 100%;
  font-family: 'Epilogue', sans-serif; /* Apply Epilogue font */
}

.select {
  width: 100%;
  padding: 10px 12px; /* Adjust padding to center the text vertically */
  border: 1px solid #000;
  border-radius: 8px;
  /* font-size: 16px;  */
  color: #000;
  font-family: 'Epilogue', sans-serif; /* Consistent font family */
  appearance: none; /* Remove default arrow */
  line-height: 1.5; /* Adjust line-height for better vertical alignment */
}

.arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none; /* Make sure the arrow doesn't block clicks */
}

.select:focus {
  outline: none;
  border-color: #000; /* Change border color on focus */
}
