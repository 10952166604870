.board {
  font-family: 'Epilogue', sans-serif;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.header {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.columnHeader {
  flex: 1;
  padding: 12px;
  font-weight: bold;
  color: #000; /* Ensure header text is black */
  text-align: left;
}

.firstColumn {
font-weight: bold;
  color: #000; /* Always ensure the first column is black */
}

.row {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.cell {
  flex: 1;
  padding: 12px;
  color: #808080; /* Default color for non-first columns */
  text-align: left;
}

.cell:first-child {
  color: #000; /* Ensure the first column in every row is always black */
}

.cellSkeleton {
  flex: 1;
  padding: 12px 12px 12px 12px;
  margin-right: 10%;
  max-width: 15%;
  text-align: left;
}

.rowSkeleton {
  display: flex;
  border-bottom: 1px solid #ccc;
}